import React, {ChangeEvent, FormEvent, useState} from 'react';
import Map from '../../map/Map';
import {MapLayersEnum} from '../../../context/map/types/maplayers.enum';
import {DistributionItem as DistributionItemProps} from '../../../pages/gdzie-kupic';
import CustomLink from '../../shared/custom-link/CustomLink';
import HiddenPhoneNumber from '../../hidden-phone-number/HiddenPhoneNumber';
import {IMAGES} from '../../../context/map/Images';
import Tags from '../distribution-item-list/Tags';

const DistributionItem = ({data}: {data: DistributionItemProps}) => {
  const [inputAddress, setInputAddress] = useState('');
  const {address, name, phone, email, installation, registration} = data;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputAddress(event.target.value);
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    window.open(`https://www.google.pl/maps/dir/${encodeURIComponent(inputAddress)}/${encodeURIComponent(address)}`, '_blank');
  }

  return (
    <section className={'flex items-center justify-center pt-24 sm:px-8 lg:px-36 py-10 pb-36 relative'}>
      <div className={'flex flex-col w-full max-w-[1170px]'}>
        <CustomLink className={'flex gap-2 items-center w-fit'} isInternal to={'/gdzie-kupic/'}>
          <span className={'mtr-icon text-sm'}>chevron_left</span>
          <span className={'text-lg'}>Wróć do sieci sprzedaży</span>
        </CustomLink>
        <div className={'flex flex-col items-center px-4 sm:px-0 max-w-[780px] self-center'}>
          <h1 className={'text-3xl sm:text-4xl my-8 sm:my-12 text-center'}>{name}</h1>
          <div className={'flex flex-col md:flex-row gap-x-12 w-full mb-8'}>
            <div className={'flex flex-col'}>
              <p className={'text-lg sm:text-xl mb-2 sm:mb-4'}>Dane kontaktowe</p>
              <div className={'flex flex-col gap-2 text-sm sm:text-base'}>
                <span>
                  <span className={'font-black'}>Adres: </span>
                  <span>{address}</span>
                </span>
                <span>
                  <span className={'font-black'}>Telefon: </span>
                  {phone ?
                    <HiddenPhoneNumber phoneNumber={phone}/> :
                    <span>-</span>
                  }
                </span>
                <span>
                  <span className={'font-black'}>E-mail: </span>
                  {email ?
                    <CustomLink isInternal={false} to={`mailto:${email}`}>{email}</CustomLink> :
                    <span>-</span>
                  }
                </span>
              </div>
              <div className={'mt-6 sm:mt-8'}>
                <Tags className={'flex flex-col'} registration={registration} installation={installation}/>
              </div>
            </div>
            <form onSubmit={(e) => onSubmit(e)} className={'flex flex-col mb-3.5 mt-6 sm:mt-8 md:mt-0'}>
              <label className={'text-lg sm:text-xl mb-2 sm:mb-4'} htmlFor="address">Wyznacz trasę dojazdu</label>
              <div className={'flex gap-2 mb-1.5'}>
                <input
                  onChange={(e) => {handleChange(e)}}
                  type='text'
                  autoFocus
                  id='address'
                  name={'address'}
                  className={`pl-3.5 text-sm w-full sm:w-80 placeholder:text-neutrals-40 rounded h-10 sm:h-12 border-2 focus:outline-none block`}
                  placeholder={'Podaj adres'}
                />
              </div>
              <input
                type='text'
                id='address-to'
                disabled
                name={'address-to'}
                className={`disabled:text-neutrals-40 bg-gray-50 pl-3.5 text-sm w-full sm:w-80 placeholder:text-neutrals-40 rounded h-10 sm:h-12 border-2 focus:outline-none block`}
                value={address}
                placeholder={'Podaj adres'}
              />
              <button
                type={'submit'}
                className={'mtr-btn mt-2 mtr-btn-[secondary] w-fit'}
              >
                <span>WYZNACZ TRASĘ</span>
              </button>
            </form>
          </div>

        </div>
          <Map
            layer={MapLayersEnum.DISTRIBUTION}
            images={IMAGES}
            markers={[data]}
            className={'w-full h-[500px] sm:h-[600px] sm:rounded-lg bg-neutrals-70'}
          />
      </div>
      <div className={'h-[1000px] lg:h-[800px] w-full absolute top-0 left-0 z-0 bg-neutrals-90 z-[-1]'}/>
    </section>
  )
}

export default DistributionItem;
