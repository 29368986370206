import {graphql, HeadProps, PageProps} from 'gatsby';
import React from 'react';
import {SiteMetadataConfigProps} from '../types/metadata-config-props.type';
import {PageContextType} from '../types/page-context.type';
import DistributionItem, {DistributionItemProps} from '../components/device/distribution-item/DistributionItem';
import Layout from '../components/Layout';
import StructuredData from '../assets/structured-data';
import TextFormatter from '../utils/TextFormatter';
import {ISpecialOfferSection} from '../components/templates/special-offer/special-offer.interface';
import {IHelpSection} from '../components/templates/help/help-section.interface';
import SpecialOffer from '../components/templates/special-offer/SpecialOffer';
import Help from '../components/templates/help/Help';
import {MapProvider} from '../context/map/map.context';

type DistributionItemType = {
  strapiDistributionItem: DistributionItemProps,
  strapiDeviceDistribution: {
    specialOffer: ISpecialOfferSection,
    help: IHelpSection,
  }
} & SiteMetadataConfigProps

type DistributionPageContext = {id: string, slug: string} & PageContextType;

const DistributionItemTemplate = ({data, pageContext}: PageProps<DistributionItemType, DistributionPageContext>) => {
  const distributionItem = data.strapiDistributionItem;
  distributionItem.slug = pageContext.slug;

  delete pageContext.breadcrumb.crumbs[2];
  pageContext.breadcrumb.crumbs[1].crumbLabel = 'Gdzie kupić';

  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={data.strapiDistributionItem.name || data.strapiDistributionItem.address}>
      <MapProvider>
        <DistributionItem data={distributionItem}></DistributionItem>
      </MapProvider>
      <SpecialOffer specialOffer={data.strapiDeviceDistribution.specialOffer} />
      <Help helpSection={data.strapiDeviceDistribution.help} />
    </Layout>
  )
}

export default DistributionItemTemplate;

export const Head = (props: HeadProps<DistributionItemType, DistributionPageContext>) => {
  const slugs = props.location.pathname.split('/');
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {address, name, email, phone, coordinates} = props.data.strapiDistributionItem;
  const formattedPhone = TextFormatter.formatPhoneNumber(phone);

  return (
    <>
      <title>Gdzie kupić - {name || address}</title>
      <meta name="description" content={`Kup urządzenie MYTRACKO stacjonarnie pod adresem: ${address}`}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: 'Gdzie kupić',
          item: siteUrl + '/' + slugs[1] + '/'
        },
        {
          name:  name || address,
          item: siteUrl + '/' + slugs[1] + '/' + slugs[2] + '/' + props.pageContext.slug + '/'
        },
      ], [
        {
          "@type": "Store",
          "currenciesAccepted": "PLN",
          "address": address,
          "email": email,
          "telephone": formattedPhone,
          "name": name,
          "geo": {
            "latitude": coordinates.lat,
            "longitude": coordinates.lng
          }
        }
      ])}
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    strapiDeviceDistribution {
      specialOffer {
        button {
          label
          url
        }
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      help {
        description
        email
        number
        title
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiDistributionItem(id: {eq: $id}) {
      address
      email
      name
      phone
      installation
      registration
      coordinates {
        lat
        lng
      }
    }
  }
`
